<template>
  <div>
    <b-card
      no-body
      class="mb-2"
    >
      <b-card-header class="p-2">
        <b-card-title class="font-medium-2 card-title">
          <feather-icon
            icon="FileIcon"
            size="18"
          />
          <span class="align-middle ml-50">
            Imprimir Etiquetas Por Chave
          </span>
        </b-card-title>
      </b-card-header>
      <hr class="my-0">
      <validation-observer #default="{}">
        <b-form>
          <div class="p-2">
            <b-row>
              <b-col
                md="5"
              >
                <validation-provider
                  #default="validationContext"
                  name="Chave"
                >
                  <b-form-group
                    label="Bipe a chave da nota fiscal"
                    label-for="chave-nota"
                  >
                    <b-form-input
                      id="chave-nota"
                      ref="refChave"
                      v-model="chave"
                      autofocus
                      trim
                      @blur="enviaChave"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="Tipo Etiqueta"
                  rules="required"
                >
                  <b-form-group
                    label="Tipo Etiqueta"
                    label-for="tipoEtiquetaId"
                  >
                    <v-select
                      id="tipoEtiquetaId"
                      v-model="tiposDeEtiquetas"
                      :options="tiposDeEtiquetasOptions"
                      :reduce="val=>val.value"
                      :clearable="false"
                    />
                    <span class="undermessage-error">
                      {{ validationContext.errors[0] }}
                    </span>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                class="align-content-center mb-1"
                md="3"
              >
                <b-form-checkbox
                  id="impressoraPadrao"
                  v-model="impressoraPadrao"
                >
                  Impressora Padrão
                </b-form-checkbox>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card
      v-show="movimentacoes && movimentacoes.length > 0"
      no-body
      class="mb-2"
    >
      <b-card-header>
        <b-card-title class="font-medium-2 card-title">
          <feather-icon
            icon="TagIcon"
            size="18"
          />
          <span class="align-middle ml-50">
            Resultados
          </span>
        </b-card-title>
      </b-card-header>
      <hr class="my-0">
      <div class="m-2">
        <b-row>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                placeholder="Pesquisar..."
              />
            </div>
          </b-col>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>movimentações por página</label>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="tableRef"
        show-empty
        empty-text="Nenhum resultado encontrado."
        :items="movimentacoes"
        :fields="tableColumns"
        :filter="search"
        :per-page="perPage"
        :current-page="currentPage"
        sticky-header
        empty-filtered-text="Nenhum resultado encontrado com os filtros informados."
        @filtered="onFiltered"
      >
        <template #head(select)="{}">
          <div class="d-flex justify-content-center">
            <b-form-checkbox
              v-model="allSelected"
              @change="selectAllHandler"
            />
          </div>
        </template>
        <template #cell(select)="data">
          <div class="d-flex justify-content-center">
            <b-checkbox v-model="data.item.selected" />
          </div>
        </template>
        <template #cell(situacao)="data">
          <b-badge
            :class="`badge badge-light-${data.item.situacaoImpressao === 'IMPRESSO' ? 'success' : 'danger'}`"
          >
            {{ data.item.situacaoImpressao === 'IMPRESSO' ? 'Impresso' : 'Aguardando Impressão' }}
          </b-badge>
        </template>
        <template #cell(nomeServico)="data">
          {{ serviceDictionary(data.item.nomeServico) }}
        </template>
        <template #cell(visualizar)="data">
          <b-button
            variant="link"
            @click="openModal(data)"
          >
            <feather-icon
              icon="EyeIcon"
              size="18"
            />
          </b-button>
        </template>
        <template slot="bottom-row">
          <td/><td/><td/><td/><td/><td/>
          <td class="table-footer">
            TOTAL
          </td>
          <td class="table-footer">
            {{ totalGeral() }}
          </td>
          <td class="table-footer">
            {{ totalSoma(14) }}
          </td>
        </template>
      </b-table>
      <hr class="mt-0 mb-1">
      <div class="m-2">
        <b-row v-if="totalRows > 0">
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando do 
              {{ Math.min(((currentPage - 1) * perPage) + 1, totalRows) }}º até o 
              {{ Math.min(((currentPage - 1) * perPage) + perPage, totalRows) }}º de {{
                totalRows
              }} resultados</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <validation-observer
        #default="{validate}"
        ref="impressaoValidation"
      >
        <div class="p-2 pb-4 d-flex flex-row-reverse align-items-center">
          <b-button
            variant="primary"
            class="ml-2"
            :disabled="movimentacoes.findIndex(obj => obj.selected) < 0"
            @click="validate().then(imprimirEtiquetas)"
          >
            <span class="text-nowrap">Reimprimir Selecionados</span>
          </b-button>
        </div>
      </validation-observer>
    </b-card>
    <b-modal
      ref="modal"
      title="Detalhes"
      ok-only
      ok-title="OK"
      size="xl"
      style="max-height: 10000px"
    >
      <details-card :data="modalData" />

    </b-modal>
    <b-modal
      ref="modalEtiqueta"
      title="Impressão de Etiqueta"
      ok-only
      ok-title="OK"
      size="xl"
      scrollable
      class="iframe-modal"
    >
      <b-row>
        <b-col
          cols="12"
          :sm="getDanfe ? 6 : 12"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <iframe
            :src="pdfsrc"
            class="iframe"
          />
        </b-col>
        <b-col
          v-if="getDanfe"
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <iframe
            :src="pdfsrcDanfe"
            class="iframe"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { reactive, ref, toRefs, watch } from '@vue/composition-api'
import store from '@/store'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import swal from 'sweetalert2/dist/sweetalert2.min'
import { $themeConfig } from '@themeConfig'
import utils from '@/helpers/utils'
import DetailsCard from '../components/imprimirDetails'

export default {
  name: 'IndexVue',
  components: { DetailsCard },
  setup() {
    const appLoading = $themeConfig.app.appLoadingImage
    
    const gerarEtiquetaStore = createNamespacedHelpers('gerarEtiquetas')

    const showResultados = ref(false)

    const modal = ref(null)

    const modalEtiqueta = ref(null)

    const tableRef = ref(null)

    const formatoEtiqueta = localStorage.getItem('formato-etiqueta')

    const impressoraPadrao = localStorage.getItem('impressora-padrao')

    const { clienteId } = JSON.parse(localStorage.getItem('userName'))

    const state = reactive({
      chave: '',
      movimentacoes: [],
      getEtiquetas: gerarEtiquetaStore.useGetters(['gerarEtiquetas']).gerarEtiquetas,
      getDanfe: gerarEtiquetaStore.useGetters(['gerarDanfe']).gerarDanfe,
      allSelected: false,
      modalData: {},
      modalEtiqueta: null,
      tiposDeEtiquetas: formatoEtiqueta || 'etiqueta_jadlog_16x10',
      movimentacoesId: [],
      impressoraPadrao: impressoraPadrao || false,
      pdfsrc: '',
      pdfsrcDanfe: '',
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
    })

    const table = {
      search: ref(''),
      sortBy: ref(''),
      isSortDirDesc: ref(false),
      tableColumns: [
        { key: 'select', sortable: false },
        { key: 'shippmentId', sortable: true },
        { key: 'nomeServico', sortable: true, label: 'Serviço' },
        { key: 'nomeDestinatario', sortable: true, label: 'Destinatário' },
        { key: 'destinatario.cep', sortable: true, label: 'CEP' },
        { key: 'destinatario.uf', sortable: true, label: 'UF' },
        { key: 'destinatario.cidade', sortable: true, label: 'Cidade' },
        { key: 'numeroNotaFiscal', sortable: true, label: 'Nº NF', thStyle: { whiteSpace: 'nowrap' } },
        { key: 'quantidade', sortable: true, label: 'Volumes' },
        { key: 'situacao', sortable: true, label: 'Situação' },
        { key: 'visualizar', sortable: false },
      ],
    }

    watch(() => state.movimentacoes, value => {
      state.currentPage = 1
      table.search.value = ''
      if (value && value.length) {
        state.totalRows = value.length
      } else {
        state.totalRows = 0
      }
    })

    const imprimirEtiquetasRequest = obj => {
      store.dispatch('gerarEtiquetas/find', obj)
    }

    const imprimirDanfeRequest = obj => { store.dispatch('gerarEtiquetas/danfe', obj) }

    const openEtiquetaModal = data => {
      state.modalEtiqueta = data
      modalEtiqueta.value.show()
    }

    const imprimirEtiquetasSemModal = obj => {
      store.dispatch('gerarEtiquetas/findNoModal', obj)
      // .then(() => {
      //   const blobUrl = state.getEtiquetas
      //   const iframe = document.createElement('iframe')
      //   iframe.style.position = 'absolute'
      //   iframe.style.top = '-10000px'
      //   iframe.style.left = '-10000px'
      //   iframe.src = blobUrl
      //   document.body.appendChild(iframe)
         
      //   iframe.onload = () => {
      //     iframe.contentWindow.focus()
      //     iframe.contentWindow.print()
           
      //     setTimeout(() => {
      //       document.body.removeChild(iframe)
      //     }, 1000)
      //   }
      // })
      // .catch(error => {
      //   swal.fire({
      //     icon: 'error',
      //     title: 'Atenção',
      //     text: error.message,
      //     showConfirmButton: false,
      //     timer: 3000,
      //   })
      // })
    }

    watch(
      () => state.movimentacoes.filter(mov => mov.selected),
      value => {
        state.movimentacoesId = value.map(obj => obj.movimentacaoId)
      },
    )

    const selectAllHandler = bool => {
      state.movimentacoes = state.movimentacoes.map(etiqueta => ({ ...etiqueta, selected: bool }))
    }

    const imprimirEtiquetaIds = movimentacaoIds => {
      const objEtiqueta = {
        etiqueta: movimentacaoIds,
        tipoEtiqueta: state.tiposDeEtiquetas,
        reversa: false,
      }
      if (objEtiqueta.etiqueta.length > 0 && objEtiqueta.tipoEtiqueta) {
        localStorage.setItem('formato-etiqueta', state.tiposDeEtiquetas)
        localStorage.setItem('impressora-padrao', state.impressoraPadrao)
        if (state.impressoraPadrao) {
          imprimirEtiquetasSemModal(objEtiqueta)
        } else {
          imprimirEtiquetasRequest(objEtiqueta)
          imprimirDanfeRequest(objEtiqueta)
          openEtiquetaModal(state.modalEtiqueta)
        }
      }
    }

    const imprimirEtiquetas = () => {
      const sortedIds = tableRef.value.sortedItems.filter(item => state.movimentacoesId.includes(item.movimentacaoId)).map(mov => mov.movimentacaoId)
      const objEtiqueta = {
        etiqueta: sortedIds,
        tipoEtiqueta: state.tiposDeEtiquetas,
        reversa: false,
      }
      if (objEtiqueta.etiqueta.length > 0 && objEtiqueta.tipoEtiqueta) {
        localStorage.setItem('formato-etiqueta', state.tiposDeEtiquetas)
        localStorage.setItem('impressora-padrao', state.impressoraPadrao)
        if (state.impressoraPadrao) {
          imprimirEtiquetasSemModal(objEtiqueta)
        } else {
          imprimirEtiquetasRequest(objEtiqueta)
          imprimirDanfeRequest(objEtiqueta)
          openEtiquetaModal(state.modalEtiqueta)
        }
      }
      if (objEtiqueta.etiqueta.length === 0) {
        swal.fire({
          icon: 'error',
          title: 'Algo deu errado',
          text: 'Informe ao menos uma etiqueta da lista',
          showConfirmButton: false,
          timer: 3000,
        })
      }
    }

    const openModal = data => {
      state.modalData = data.item
      modal.value.show()
    }

    const imprimirESalvar = etiquetas => {
      imprimirEtiquetaIds(etiquetas.map(etq => etq.movimentacaoId))
      etiquetas.forEach(etq => state.movimentacoes.push({
        ...etq,
        situacao: 'IMPRESSO',
      }))
    }

    const enviaChave = () => {
      if (state.chave) {
        if (state.movimentacoes.findIndex(etq => etq.chaveNotaFiscal === state.chave) > -1) {
          swal.fire({
            icon: 'info',
            title: 'Atenção',
            html: 'A chave informada já foi bipada.<br />Ela está na grid abaixo.',
            showConfirmButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
          state.chave = ''
        } else {
          appLoading.show()
          store.dispatch('imprimir/findByChave', { clienteId, chave: state.chave })
            .then(resp => {
              state.chave = ''
              appLoading.hide()
              if (resp.success) {
                const etiquetas = resp.body
                if (etiquetas[0].situacao === 'IMPRESSO') {
                  const date = etiquetas[0].dataImpressao.split('T')[0]
                  swal.fire({
                    icon: 'info',
                    title: 'Atenção',
                    html: `A chave informada já foi bipada no dia ${date}.<br /> Deseja imprimir novamente?`,
                    showConfirmButton: true,
                    showCancelButton: true,
                    customClass: {
                      confirmButton: 'btn btn-primary',
                      cancelButton: 'btn btn-secondary ml-1',
                    },
                    confirmButtonText: 'SIM',
                    cancelButtonText: 'CANCELAR',
                  }).then(response => {
                    if (response.isConfirmed) {
                      imprimirESalvar(etiquetas)
                    }
                  })
                } else {
                  imprimirESalvar(etiquetas)
                }
              } else {
                swal.fire({
                  icon: 'error',
                  title: 'Atenção',
                  text: `${resp.body}`,
                  showConfirmButton: false,
                  timer: 3000,
                })
              }
            })
        }
      }
    }

    watch(() => state.chave, (newValue, oldValue) => {
      const qtdChar = newValue.length - oldValue.length
      if (qtdChar > 1) {
        enviaChave(newValue)
      }
    })

    watch(
      () => state.getEtiquetas,
      value => {
        state.pdfsrc = value
      },
    )

    watch(() => state.getDanfe, value => {
      state.pdfsrcDanfe = value
    })

    const tiposDeEtiquetasOptions = [
      { value: 'etiqueta_jadlog_16x10', label: 'Etiqueta 16X10' },
      { value: 'etiqueta_jadlog_10x10', label: 'Etiqueta 10X10' },
      { value: 'etiqueta_jadlog_A4', label: 'Etiqueta 16x10 A4' },
      { value: 'danfe_simplificado_16x10', label: 'Etiqueta 16x10 com Danfe' },
      { value: 'etiqueta_jadlog_mini_danfe_16x10', label: 'Etiqueta 16x10 mais mini Danfe' },
    ]

    const totalGeral = () => {
      let total = 0
      if (tableRef && tableRef.value) {
        total = tableRef.value.filteredItems.length
      }
      return total
    }

    const totalSoma = i => {
      let total = 0
      if (tableRef && tableRef.value) {
        const array = tableRef.value.filteredItems
        array.forEach(obj => {
          const values = Object.values(obj)
          if (values[i]) {
            total += Number(values[i])
          }
        })
      }
      return total
    }

    const onFiltered = filteredItems => {
      state.currentPage = 1
      state.totalRows = filteredItems.length
    }

    return {
      ...toRefs(state),
      ...utils,
      tableRef,
      ...table,
      selectAllHandler,
      modal,
      modalEtiqueta,
      openEtiquetaModal,
      openModal,
      imprimirEtiquetas,
      showResultados,
      tiposDeEtiquetasOptions,
      enviaChave,
      totalGeral,
      totalSoma,
      onFiltered,
    }
  },
}
</script>

<style lang="scss">
.iframe-modal {
  height: 100vh;
}

.iframe {
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.table-footer {
    font-weight: bold;
    background: #E9E9E9;
    border-bottom: 3px solid #F55559;
    font-size: 1.2rem;
  }
  .swal2-html-container {
    text-align: center;
  }
</style>
